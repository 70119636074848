<template>
  <section class="mt-2">

    <b-form inline class="mt-2">
        <b-button @click="refreshList" class="no-border"><i class="fal fa-sync"></i></b-button>
       <AddFunctionBox @addedResult="refreshList" :organisation="organisation">
        <b-btn class="inlineBtn" variant="dark">
          {{ $t('ADD') }}
        </b-btn>
      </AddFunctionBox>
      <label class="sr-only" for="searchtext-functionbox">{{$t('SEARCH')}}</label>
      <b-form-input trim
        id="searchtext-functionbox"
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        @keydown.enter.prevent
        class="searchText hidden-mobile"
      ></b-form-input>
      <label class="sr-only" for="list-functionbox-limit">{{$t('LIMIT')}}</label>
      <b-form-select  name="functionboxes_limit" id="list-functionbox-limit" v-model="limit" class="limit hidden-mobile">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>

      
      <div class="hidden-not-mobile w-100 mt-2">
        <b-form inline>
          <label class="sr-only" for="searchtext-functionbox-mobile">{{$t('SEARCH')}}</label>
          <b-form-input trim
            id="searchtext-functionbox-mobile"
            class="ml-2 i-100 searchText"
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
          ></b-form-input>
          <label class="sr-only" for="list-functionbox-limit-mobile">{{$t('LIMIT')}}</label>
          <b-form-select id="list-functionbox-limit-mobile" name="meetings_limit_mobile"  v-model="limit" class="limit">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="25">25</b-form-select-option>
            <b-form-select-option value="45">45</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-form>
      </div>
    </b-form>


    <div class="mt-2">
      <b-table small class="clickable" :busy="loading" bordered :items="items" hover :fields="fields" @row-clicked="clickedRow">

        <template v-slot:cell(name)="data">
          <span v-if="data.item.verified == 0">{{$t('NOT_VERIFIED')}} - </span>{{ data.item.name }}
        </template>

        <template v-slot:cell(id)="data">
         {{ data.item.name }} - {{ data.item.email }}
        </template>

          <template v-slot:cell(size)="data">
            {{ bytesToSize(data.item.size) }}
          </template>
        

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="limit"
        align="center"
        pills
        @input="getItems"
      >
      </b-pagination>

      <b-sidebar id="user-sidebar" sidebar-class="big_sidebar" right backdrop no-slide shadow v-model="show_sidebar">
        <div class="px-3 py-2">
          <FunctionBoxPage 
            v-if="functionbox_uuid != ''"
            @removedBox="removedBox" 
            @updatedBox="updatedBox" 
            :functionbox_uuid="functionbox_uuid" 
            :key="functionbox_uuid">
          </FunctionBoxPage>
        </div>
      </b-sidebar>
    </div>
  </section>
</template>
<script>
import AddFunctionBox from '@/components/Organisation/AddFunctionBox';
import FunctionBoxPage from '@/components/FunctionBox/Page';
export default {
  components: { AddFunctionBox, FunctionBoxPage },
  props: {
    organisation: {
      type: Object,
      default: null
    }
  },
  data() {
    return { 
      loading: false,
      show_sidebar: false,
      currentPage: 1,
      searchText: '',
      items: [],
      totalItems: 0,
      limit: 10,
      functionbox_uuid: '',
      state: 1,
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: 'hidden'
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break hidden-mobile",
          sortable: false
        },
        {
          key: "email",
          label: this.$t("EMAIL"),
          class: "text-break hidden-mobile",
          sortable: false
        },
        {
          key: "accounts",
          label: this.$t("ACCOUNTS"),
          class: "text-break w-150  hidden-mobile",
          sortable: false
        },
        {
          key: "size",
          label: this.$t("SIZE"),
          class: "text-break w-150  hidden-mobile",
          sortable: false
        }
      ]
    };
  },
  methods: {
    refreshList: function()
    {
      this.getItems();
    },
    updatedBox: function() {
      this.functionbox_uuid = '';
      this.show_sidebar = false;
      this.getItems();
    },
    removedBox() {
      this.functionbox_uuid = '';
      this.show_sidebar = false;
      this.getItems();
    },
    showState: function(state) {
      if (state === 1) {
        return this.$t("ACTIVE");
      }
      return this.$t("INACTIVE");
    },
    getItems: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/administration/functionbox/list", {
          page: this.currentPage,
          organisation_id: this.organisation.id,
          limit: parseInt(this.limit),
          search_text: this.searchText
        })
        .then(response => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    clickedRow: function(record) {
      this.functionbox_uuid = '';
      this.functionbox_uuid = record.functionbox_uuid;
      this.show_sidebar= true; 
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getItems();
      }
      if (this.searchText.length >= 2) {
        this.getItems();
      }
    },
    limit: function() {
      this.getItems();
    }
  },
  mounted: function() {
    this.getItems();
  }
};
</script>
<style></style>